import {useState, useRef, useEffect} from 'react';
import {useRouter} from "next/router";
import useClickOutSide from '../hooks/useClickOutSide';

import Link from './UI/Link'
import Image from './UI/Image'

const GuestHeader = ({fixed}) => {
    const [state, setState] = useState('menu')
    const [resourceMenu, setResourceMenu] = useState(false)
    const [directoryMenu, setDirectoryMenu] = useState(false)
    const resourceToggler = useRef(null)
    const resource = useRef(null)
    const directoryToggler = useRef(null)
    const directory = useRef(null)

    const router = useRouter()

    useEffect(() => {
        resourceMenu && setResourceMenu(false)
        directoryMenu && setDirectoryMenu(false)

        return () => setState('close')
    },[router.asPath])

    useClickOutSide(resource, () => setResourceMenu(false), resourceToggler)
    useClickOutSide(directory, () => setDirectoryMenu(false), directoryToggler)

    return <header className={`guest main${router.pathname.startsWith('/registry') ? '' : ' transparent'} state-${state} ${fixed ? 'fixed' : ''}`} onMouseLeave={() => setState('menu')}>
        <div className="open-menu">
            <Image width={20} height={20} placeholder={null} src="/images/hamburger.svg" alt="open" onClick={() => setState('open')}/>
        </div>
        <div className="close-menu">
            <Image width={10} height={10} placeholder={null} src="/images/close.svg" alt="close" onClick={() => setState('close')}/>
        </div>
        <div className="back-menu">
            <Image width={8} height={12} placeholder={null} src="/images/arrow-left.svg" alt="back" onClick={() => setState('open')}/>
        </div>
        <nav>
            <ul className="left-side">
                <li onClick={() => setState('close')}>
                    <Link href="/how-it-works">HOW IT WORKS</Link>
                </li>
                <li className={`header-dropdown${resourceMenu ? ' active' : ''}`}>
                    <p onClick={() => setResourceMenu(!resourceMenu)}
                       ref={resourceToggler}>
                        RESOURCES
                    </p>
                    <div ref={resource}>
                        <Link href="/journal">JOURNAL</Link>
                        <Link href="/podcast">PODCAST</Link>
                        <Link href="/support-directory">DIRECTORY</Link>
                    </div>
                </li>
                <li className={`header-dropdown${directoryMenu ? ' active' : ''}`}>
                    <p onClick={() => setDirectoryMenu(!directoryMenu)}
                       ref={directoryToggler}>
                        <span className="new-label">DIRECTORY</span>
                    </p>
                    <div ref={directory}>
                        <Link href="/support-directory">Mothers: Browse Providers</Link>
                        <Link href="/interest-form">Providers: Join Our Directory</Link>
                    </div>
                </li>
            </ul>
            <Link href="/" className="logo" activeClass="">
                <Image width={325} height={33} placeholder={null} src="/images/LHM-logo-desktop.svg" alt="logo"/>
            </Link>
            <ul className="right-side" onClick={() => setState('close')}>
                <li className="sign-in-link">
                    <Link href="/signin">SIGN IN</Link>
                </li>
                <li className="sign-in-link">
                    <Link href="/findregistry">FIND A REGISTRY</Link>
                </li>
                <li className="sign-up-link">
                    <Link className="btn-transparent" href="/get-started">GET STARTED</Link>
                </li>
            </ul>
        </nav>
    </header>
}

export default GuestHeader
